.unconnected {
  height: 100vh;
  width: 100vw;
  background-image: url('../../public/warehousebg.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.hsInput {
  background-color: #67D0B5;
}

@media (min-width: 1200px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */
  .font {
    width: 100%;
    height: 100vh;
    background-color: white;
    padding: 2% 5% 6% 1%;
    display: "inline-flex";
    align-items: center;
    .Polaris-Card {
      background-color: #F6F6F7;
    }
  }

  .imageBlock {
    width: 55%;
  }

  .formBlock {
    width: 45%;
  }

  .imageFormBlock {
    display: none;
  }

  .text {
    text-align: center;
    font-size: 25px;
    margin-top: -6%;
    margin-bottom: 7%;
    color: #67D0B5;
    font-weight: bold;
  }
  
  .happycolis {
    height: 53vh;
    width: 40vw;
    background-image: url('../../public/logisticien-connect.jpeg');
    background-size: cover;
    //background-position: center center;
    background-repeat: no-repeat;
  }

  
}


@media (max-width: 1200px) {
  /* CSS that should be displayed if width is equal to or less than 800px goes here */

  .font {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 2% 5% 6% 1%;
    display: "inline-flex";
    align-items: center;
  }

  .imageBlock {
    display: none;
  }

  .text {
    text-align: center;
    font-size: 25px;
    margin-top: -6%;
    margin-bottom: 7%;
    color: #67D0B5;
    font-weight: bold;
  }

  .formBlock {
    width: 90%;
    height: 100vh
  }
}